import React from 'react';

import MyCustomLogoFull from './logo/logo-yagan.png';

const LogoFull = () => {
  return <img src={MyCustomLogoFull} />;
};


export default LogoFull;
