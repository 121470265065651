import React from 'react';

import MyCustomLogoFull from './logo/logo-icono-32x32.png';

const LogoIcon = () => {
  return <img src={MyCustomLogoFull} />;
};


export default LogoIcon;
